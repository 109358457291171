@import url("https://fonts.googleapis.com/css2?family=Days+One&family=Open+Sans&family=PT+Serif&family=Poppins:wght@300&display=swap");

.app__footer {
  width: 100%;
  position: relative;
  z-index: 1;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  background-color: #0d0909;
  padding-top: 0;
}
.p__opensans {
    margin-top: 10px;
    margin-bottom: 20px;
  color: #fff;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-links {
  width: 100%;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: 5rem;
  padding: 0 2rem;
}

.app__footer-links_contact,
.app__footer-links_logo,
.app__footer-links_work {
  flex: 1;
  margin: 1rem;
  text-align: center;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-links_logo img:first-child {
  width: 210px;
  margin-bottom: 0.75rem;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-links_work p:nth-child(2n + 1) {
  margin-bottom: 1rem;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-links_icons {
  margin-top: 0.5rem;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-links_icons svg {
  color: #fff;
  margin: 0.7rem;
  font-size: 35px;
  cursor: pointer;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-links_icons svg:hover {
  color: #fff;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

.app__footer-headtext {
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;
  color: #fff;
  font-weight: 400;
  letter-spacing: 0.04em;
  text-transform: capitalize;
  font-size: 23px;
  line-height: 41.6px;

  margin-bottom: 1rem;
}

.footer__copyright {
  margin-top: 3rem;
  font-family:"Open Sans", Arial, Tahoma, sans-serif ;

}

@media screen and (min-width: 2000px) {
  .app__footer-headtext {
    font-size: 51px;
    line-height: 61.6px;
    font-family:"Open Sans", Arial, Tahoma, sans-serif ;

  }
}

@media screen and (max-width: 1150px) {
  .app__footer-links {
    align-items: center;
    flex-direction: column;
    font-family:"Open Sans", Arial, Tahoma, sans-serif ;

    padding: 0;
  }

  .app__footer-links_contact,
  .app__footer-links_logo,
  .app__footer-links_work {
    margin: 2rem 0;
    width: 100%;
    font-family:"Open Sans", Arial, Tahoma, sans-serif ;

  }
}

@media screen and (max-width: 650px) {
  .app__footer {
    padding: 0 0 2rem 0;
  }
}

@media screen and (max-width: 350px) {
  .app__footer-links_logo img:first-child {
    width: 80%;
    font-family:"Open Sans", Arial, Tahoma, sans-serif ;

  }
}
