.text {
  color: #fff;
  font-size: 1.1rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  cursor: pointer;
  margin-left: 45px;
}
.img{
  height: 70px; 
  width: 90px;
  margin-left: 40px;
  margin-top: 10px;
}


@media screen and (max-width: 920px) {
  .text{
    display: none;
  }
  .img{
    height: 50px; 
  width: 70px;
  margin-left: 10px;
  }
}


